import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

const TilePosts = ({ miniProjectsUpdates, miniProjects }) => {
  return (
    <div className="grid items-center grid-cols-1 mx-auto mt-4 gap-y-4 justify-items-stretch">
      {miniProjectsUpdates.map(({ node }) => {
        const miniProject = miniProjects.find((miniProject) => miniProject.node.frontmatter.miniProject === node.frontmatter.miniProject).node;
        return (
          <Link key={node.id} to={node.frontmatter.path} className="grid gap-x-4 hover:bg-gray-100 md:grid-cols-40rem40rem items-center md:justify-center text-indigo-600 hover:text-pink-600">
            <div className="tile_featuredImage justify-self-stretch">
              {node.frontmatter.featuredImage && <Img fluid={node.frontmatter.featuredImage.childImageSharp.fluid} />}
              {!node.frontmatter.featuredImage && miniProject && miniProject.frontmatter && miniProject.frontmatter.featuredImage && <Img fluid={miniProject.frontmatter.featuredImage.childImageSharp.fluid} />}
            </div>
            <div className="justify-self-center md:justify-self-end text-center md:text-right md:row-start-1 md:col-start-1">
              <h4>{miniProject.frontmatter.title}</h4>
              <h3 className="text-3xl">{node.frontmatter.title}</h3>
              <time className="text-sm">{node.frontmatter.date}</time>
            </div>
          </Link>
        );
      })}
    </div>
  );
}

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Moon Reacher" />
      <TilePosts miniProjectsUpdates={data.miniProjectUpdates.edges} miniProjects={data.miniProjects.edges} />
      <div className="pagination">
        <Link to="blog/2" rel="next" className="pagination-next">
          Next Page
        </Link>
      </div>
    </Layout>
  );
}

export default IndexPage

export const pageQuery = graphql`
{
  miniProjects: allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}, filter: {frontmatter: {type: {eq: "mini-project"}}}) {
    edges {
      node {
        id
        frontmatter {
          miniProject
          title
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  },
  miniProjectUpdates: allMarkdownRemark(
      sort: {order: DESC, fields: frontmatter___date},
      filter: {frontmatter: {type: {eq: "mini-project-update"}}},
      limit: 12
    ) {
    edges {
      node {
        id,
        frontmatter {
          date(formatString: "Do MMMM, YYYY")
          title
          miniProject
          optionListing
          path
          type
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 640) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
}

`;
